import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import ReactHtmlParser from "html-react-parser";

import useFinicity from "../core/finicity/useFinicity";

import LoadingIcon from "../components/Loading";
import BtnNext from "../components/BtnNext";
import BtnSpinnerNext from "../components/BtnSpinnerNext";
import { ArrowDown } from "../components/ArrowDown";
import FinicityConnect from "../core/finicity/Finicity";

import ClientStorage from "../utils/client-storage";
import { track } from "../utils/analytics";
import { getApplicationToken, logErrorToSentry } from "../utils/helpers";
import { FirstCharUpperCase } from "../utils/text";
import { getWhiteLogo } from "../utils/utils";
import { get, postWithAuthHeader} from "../utils/client";

import LinkBankAccountButton from "./LinkBankAccountButton";
import PaymentSucess from "./Payment_Success";
import PaymentError from "./Payment_Error";
import MigrationInProgress from "./MigrationInProgress";
import PaymentCards from "./payments/PaymentCards";

import {
  GET_PAYMENT_METHOD,
  SET_PAYMENT_METHOD,
  GET_LINK_TOKEN,
  IMAGE_PREFIX,
  PAYMENT_API,
  IMAGE_BASE_URL,
  ACCOUNT_V3_API_URL,
  PAYMENT_V3_API_URL,
} from "../constants/urls.constants";
import { EVENT } from "../constants/events.constants";
import { DASHBOARD_PATH } from "../constants/route-paths.constants";

import ReconnectAccountImg from '../images/hotlink-ok/restore-account-access.png'
import Toast from "../components/Toast";
import { UNKNOWN_LABEL } from "../constants/label.constants";
import { callTrackEvent } from "./Dashboard";

type Props = { partnerName: String };

export const AccountContext = React.createContext(null);

const cardsMap: any = [
  {
    title: "Pay Minimum Due",
    key: "minimum_payment",
    text1: "Pay Minimum",
    text2: "Due",
    is_hidden: false,
    id: "card1",
  },
  {
    title: "Pay Statement Balance",
    text1: "Pay Statement",
    text2: "Balance",
    key: "statement_balance",
    is_hidden: false,
    id: "card2",
  },
  {
    title: "Pay Current Balance",
    key: "card_balance",
    text1: "Pay Current",
    text2: "Balance",
    is_hidden: false,
    id: "card3",
  },
  {
    title: "Pay Now",
    key: "FIXED-AMOUNT",
    text1: "Enter",
    text2: "Amount",
    is_hidden: false,
    id: "card4",
  },
];

function Payment(props: Props) {
  const [data, setData] = useState(null);
  const [HasError, setHasError] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [learnMore, setLearnMore] = useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showPaymentError, setShowPaymentError] = useState(false);
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [customAmt, setCustomAmt] = useState("");
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentSuccessData, setPaymentSuccessData] = useState(null);
  const [paymentRes, setPaymentRes] = useState(null);
  const [customAmtErr, setCustomAmtErr] = useState(false);
  const [isMakingPayment, setIsMakingPayment] = useState(false);
  const [PaymentDisclaimer, setPaymentDisclaimer] = useState(null);
  const cardCSS = `w-full paymentCard h-48 cursor-pointer x-sm:py-8 x-sm:mr-0 x-sm:mb-4 md:mb-4 py-9 pl-5 pr-7 hover:bg-white hover:text-black rounded-3xl mr-4 dashboard-card-${props.partnerName}`;
  const cardCSSSelect = `activeCard`;
  const [showMigrationError, setShowMigrationError] = useState(false);
  const [showInstitutionList, setShowInstitutionList] = useState(false);
  const [showDisconnectedMethodError, setShowDisconnectedMethodError] = useState({
    show: false,
    message: null
  })

  const [selected, setSelected] = useState(null);
  const listRef = useRef(null);
  const partnerName = props.partnerName !== "ccbank" ? props.partnerName : null;
  const logo_image = getWhiteLogo(partnerName, false);
  const down_arrow =
    props.partnerName === "customers_bank" || props.partnerName === "blockfi"
      ? `${IMAGE_BASE_URL}/down-arrow.png`
      : `${IMAGE_BASE_URL}/chevron-down.png`;

  const { container = {} } = paymentRes?.payment_detail || {};
  const { balance_footer_detail = {} } = container;
  const desc = balance_footer_detail.desc || "";
  const long_desc = balance_footer_detail.html_desc || "";
  const more_info_title = "Learn More";
  const USE_FINICITY = window["appConfig"].USE_FINICITY === "true";

  const accessToken = getApplicationToken();

  const { initiateFinicityConnectFix } = useFinicity({
    fetchOnLoad: false,
    accessToken,
  });

  const getAmount = useCallback(
    (key) => {
      const amt = data?.credit_line[key] ?? 0;

      if (
        paymentRes?.payment_detail?.metadata?.payment_status !== "new_user" &&
        !(parseFloat(amt) > 0)
      ) {
        return "Paid";
      }

      return parseFloat(amt);
    },
    [paymentRes, data]
  );

  const has_no_dues = useCallback(() => {
    return !cardsMap
      .map((c) => c.key)
      .map((t) => data.credit_line[t])
      .some((b) => parseFloat(b) > 0);
  }, [data]);

  const should_allow_selection = useCallback(
    (key) => {
      const amt = data?.credit_line[key];
      if (key === "FIXED-AMOUNT" && has_no_dues()) {
        return false;
      }

      if (parseFloat(amt) === 0) {
        return false;
      }

      return true;
    },
    [has_no_dues, data]
  );

  const toggleInstitutionList = useCallback((show) => {
    setShowInstitutionList(show);
  }, []);

  React.useEffect(() => {
    track(EVENT.payBankGetTokenApi);
    async function createLinkToken() {
      postWithAuthHeader(
        GET_LINK_TOKEN,
        {},
        EVENT.pay_bank_account_linked_token_fetch_failed
      ).then((res: any) => {
        let token = res?.data?.link_token;
        ClientStorage.setLocal("link_token", token);
        ClientStorage.setLocal("redirectUrl", window.location.href);
        setLinkToken(token);
        track(EVENT.payBankTokenApiSuccess);
      });
    }

    let metadata = ClientStorage.getLocal("plaid-metadata");

    if ((metadata === "null" || metadata === null) && !USE_FINICITY) {
      createLinkToken();
    } else {
      if (ClientStorage.getLocal("plaid-metadata")) {
        let metadata = JSON.parse(ClientStorage.getLocal("plaid-metadata"));
        let data = Object.assign({}, metadata, {
          bank_token: ClientStorage.getLocal("bank_token"),
        });
        updatePaymentMethod(data);
        if (!USE_FINICITY) {
          createLinkToken();
        }
      }
    }
  }, [USE_FINICITY]);

  useEffect(() => {
    setLoading(true);
    track(EVENT.payments_screen_view_initiated);
    Promise.allSettled([
      get(ACCOUNT_V3_API_URL),
      get(GET_PAYMENT_METHOD),
      get(PAYMENT_V3_API_URL),
    ])
      .then(([accountDataRes, paymentDataRes, autopayRes]) => {
        return [accountDataRes, paymentDataRes, autopayRes].map((response) => {
          return response.status === "fulfilled" ? response.value : null;
        });
      })
      .then(([accountData, paymentMethodData, paymentData]) => {
        paymentData?.payment_home_card?.is_migration_in_progress
          ? setShowMigrationError(true)
          : setShowMigrationError(false);
        setPaymentRes(paymentData);
        setData(accountData);
        try {
          callTrackEvent(EVENT.payments_screen_viewed, {
            accountData,
            eventData: {
              last_four_digits:
                paymentMethodData?.methods?.length > 0
                  ? paymentMethodData?.methods[0]?.account_last_4_digits
                  : UNKNOWN_LABEL,
              payment_type: selected?.key || UNKNOWN_LABEL,
              amount:
                selected?.key === "FIXED-AMOUNT"
                  ? customAmt
                  : accountData?.credit_line[selected?.key],

              message: paymentData?.payment_detail?.header
                ? `${paymentData?.payment_detail?.header?.title} ${paymentData?.payment_detail?.header?.subtitle}`
                : UNKNOWN_LABEL,
            },
          });
        } catch (error) {
          console.error(error);
        }
        if (paymentMethodData?.methods?.length > 0) {
          setPaymentMethods(paymentMethodData?.methods);
          setSelectedPaymentMethod(paymentMethodData.methods[0]);
        }
        if (paymentData?.payment_detail?.metadata?.payment_disclaimer) {
          setPaymentDisclaimer(
            paymentData.payment_detail.metadata.payment_disclaimer
          );
        }
      })
      .catch((er) => {
        track(EVENT.payments_screen_view_failed);
        setHasError(true);
      })
      .finally(() => setLoading(false));
  }, []);

  // this effect is needed to se the selected card on load
  useEffect(() => {
    if (!data || selected) {
      return;
    }

    if (showMigrationError) return;

    const non_zero_amount_card = [...cardsMap].find(({ key }) => {
      return key && parseFloat(data.credit_line[key]) > 0;
    });

    if (non_zero_amount_card) {
      setSelected(non_zero_amount_card);
    } else {
      setSelected(cardsMap.find((c) => c.key === "FIXED-AMOUNT"));
    }
  }, [selected, data]);

  function onSuccess(token: any, metadata: any) {
    track(EVENT.payment_bank_connected);
    track(EVENT.addBankClicked);
    updatePaymentMethod(metadata);
  }

  const updatePaymentMethod = (data) => {
    track(EVENT.payAddBankApiRequested);
    setBtnLoading(true);

    data.hasOwnProperty("status") && delete data.status;

    postWithAuthHeader(SET_PAYMENT_METHOD, data, EVENT.payment_failed)
      .then((res: any) => {
        ClientStorage.deleteLocal("bank_token");
        ClientStorage.deleteLocal("plaid-metadata");
        ClientStorage.deleteLocal("is_plaid_callback");

        get(GET_PAYMENT_METHOD).then((res) => {
          setPaymentMethods(res.methods);
          setSelectedPaymentMethod(res?.methods?.[0]);
        });
        track(EVENT.payAddBankApiSuccess);
      })
      .catch((er) => {
        setHasError(true);
        track(EVENT.payAddBankApiFailed);
      })
      .finally(() => {
        setBtnLoading(false);
        listRef?.current?.scrollTo(0, 0);
      });
  };

  const showOptions = () => {
    setShowPaymentMethod(!showPaymentMethod);
  };

  if (HasError) {
    return (
      <div className="dashboard-error text-2xl text-center pt-14">
        Some error occured fetching details. Please try again.
      </div>
    );
  }

  if (showMigrationError) {
    return <MigrationInProgress partnerName={props.partnerName} />;
  }

  if (Loading) {
    return <LoadingIcon className="mx-auto" />;
  }

  const makePayment = () => {
    const amount =
      selected?.key === "FIXED-AMOUNT"
        ? customAmt
        : data.credit_line[selected.key];

    const payload = {
      frequency: "INSTANT",
      type: "FIXED-AMOUNT",
      method_id: selectedPaymentMethod.id,
      amount,
    };

    try {
      callTrackEvent(EVENT.payApiRequested, {
        accountData: data,
        eventData: {
          last_four_digits:
            selectedPaymentMethod?.account_last_4_digits || UNKNOWN_LABEL,
          payment_type: selected?.key || UNKNOWN_LABEL,
          amount: amount,
          message: paymentRes?.payment_detail?.header
            ? `${paymentRes?.payment_detail?.header?.title} ${paymentRes?.payment_detail?.header?.subtitle}`
            : UNKNOWN_LABEL,
        },
      });
    } catch (error) {
      console.error(error);
    }

    setIsMakingPayment(true);
    postWithAuthHeader(
      PAYMENT_API,
      payload,
      EVENT.one_time_payment_posting_failed
    )
      .then((res: any) => {
        setPaymentSuccessData(res.data);
        if (res.data.id) {
          setShowPaymentError(false);
          setShowPaymentSuccess(true);
        }
        track(EVENT.payApiSuccess);
      })
      .catch((e) => {
        console.error(e);
        setShowPaymentError(true);
        setShowPaymentSuccess(false);
        logErrorToSentry(EVENT.payApiFailed, e.message, e);
        track(EVENT.payApiFailed, { error: e.message });
      })
      .finally(() => {
        setIsMakingPayment(false);
      });
  };

  let longInfo = "";
  const customErr = customAmt === "" || Number(customAmt) <= 0.01;
  const selectedAmount =
    selected.key !== "FIXED-AMOUNT" && data.credit_line[selected.key];
  function renderPaymentDisclaimer(disclaimer_str) {
    let formatedDate = moment(new Date()).format("MMM DD, YYYY");
    let amt = selectedAmount
      ? `$${String(selectedAmount)}`
      : String(customAmt).length > 0
      ? `$${customAmt}`
      : "$0.00";
    let selectedBankName = "";

    if (selectedPaymentMethod) {
      const { bank_name, account_type } = selectedPaymentMethod;
      selectedBankName = `${bank_name} ${FirstCharUpperCase(
        account_type.toLowerCase()
      )} Account`;
    }

    return ReactHtmlParser(
      String(disclaimer_str)
        .replace("${payment_type}", `"${selected.title}"`)
        .replace("${amount}", amt)
        .replace("${date}", formatedDate)
        .replace("${bank_name}", selectedBankName)
    );
  }

  let paymentButtonDisable =
    (customAmtErr && selected.key === "FIXED-AMOUNT") ||
    (customErr && selected.key === "FIXED-AMOUNT") ||
    (selected.key !== "FIXED-AMOUNT" && selectedAmount <= 0) || selectedPaymentMethod?.status === 'REVOKED';

  longInfo = long_desc;

  const renderMakePaymenButton = () => {
    if (selectedPaymentMethod) {
      return isMakingPayment ? (
        <BtnSpinnerNext />
      ) : (
        <BtnNext
          data-testid="make-payment-btn"
          text={
            !cardsMap.some((c) => parseFloat(data.credit_line[c.key]) > 0)
              ? "Done"
              : selected.title
          }
          className={
            paymentButtonDisable
              ? `text-black opacity-60  pay-btn-${props.partnerName} bg-white block text-center text-xl tracking-tight h-16 p-5 cursor-pointer w-72 ml-auto mr-auto mt-8 rounded-full brand-btn-solid`
              : `text-black bg-white pay-btn-${props.partnerName} block text-center text-xl tracking-tight h-16 p-5 cursor-pointer w-72 ml-auto mr-auto mt-8 rounded-full brand-btn-solid`
          }
          onClick={makePayment}
          disabled={paymentButtonDisable}
        />
      );
    } else {
      return (
        <div className="relative">
          <LinkBankAccountButton
            link_token={linkToken}
            handleSubmit={onSuccess}
            toggleInstitutionList={toggleInstitutionList}
            title="Add Bank"
            type="button"
          />
          {btnLoading && (
            <div className="loading-black align-spinner-big"></div>
          )}
        </div>
      );
    }
  };

  const handleOnReconnectClick = async (method) => {
    if(method.status === 'REVOKED') {
      const errorMessage = <span>Failed to reconnect this bank account. <br/> Please try again.</span>;
      const loadingMessage = <span>Loading...</span>
      const connectFixMessage = <span>Please wait...</span>

      try {
        setShowDisconnectedMethodError({
          show: true,
          message: loadingMessage
        })

        await initiateFinicityConnectFix(method, () => {
          setShowDisconnectedMethodError({
            show: true,
            message: connectFixMessage
          })
        }, () => {
          setShowDisconnectedMethodError({
            show: true,
            message: errorMessage
          });
        });
      } catch(error) {
        logErrorToSentry(EVENT.finicity_connect_fix_account_failed, { message: "Initiate Finicity Connect Fix failed" }, error);
        setShowDisconnectedMethodError({
          show: true,
          message: errorMessage
        });
      }
    }
  }

  return (
    <div
      className={`dashboard-${props.partnerName} dashboard-profile-${props.partnerName} new-payments-page xl:h-screen lg:h-screen py-11 px-12 flex x-sm:w-full x-sm:px-8 x-sm:pt-28 sm:pt-28`}
    >
      <div data-testid="logo" className="fixed top-10">
        <img className="w-20 partner-logo" src={logo_image} alt="logo" />
      </div>

      {showDisconnectedMethodError.show ? (
        <Toast
          cssClasses="fixed m-auto top-6 x-sm:top-2 left-0 right-0 z-50 bg-white x-sm:p-4 text-center rounded-lg disconnected-payment-method-error-toast"
          text={showDisconnectedMethodError.message}
          timeout={5000}
          onClose={() => {
            setShowDisconnectedMethodError({
              show: false,
              message: null
            })
          }}
        />
      ) : null}

      <div
        onClick={() => window.location.assign(DASHBOARD_PATH)}
        title="Back to Dashboard"
        className="w-8 cursor-pointer top-16 rounded-full x-sm:right-4 fixed right-12 page-close x-sm:top-10 sm:top-10"
      >
        <img src={`${IMAGE_PREFIX()}/close-page.png`} alt="Cancel" />
      </div>
      <>
        {showInstitutionList ? (
          <FinicityConnect
            close={() => {
              toggleInstitutionList(false);
            }}
            onSuccessfulBankConnect={() => {
              window.location.reload();
            }}
          />
        ) : null}
        <div className="lg:w-full xl:w-full x-sm:w-full md:px-20 lg:px-0 x-sm:justify-center md:mt-14 flex flex-col justify-between">
          <AccountContext.Provider
            value={{
              account: data,
              partnerName: props.partnerName,
              is_new_user:
                paymentRes?.payment_detail?.metadata?.payment_status ===
                "new_user",
              setCustomAmt,
              customAmt,
            }}
          >
            <div>
              {paymentRes?.payment_detail?.header ? (
                <div className="w-full mb-4">
                  <span className="text-b5 text-t1-color">
                    {`${paymentRes?.payment_detail.header.title} ${paymentRes?.payment_detail.header.subtitle}` ||
                      ""}
                  </span>
                </div>
              ) : null}
              <PaymentCards
                cards={cardsMap.map((card) => {
                  return {
                    ...card,
                    allow_selection: should_allow_selection(card.key),
                    amount:
                      card.key !== "FIXED-AMOUNT"
                        ? getAmount(card.key)
                        : customAmt,
                  };
                })}
                selected={selected}
                set_selected={(key) => {
                  setSelected(key);
                }}
              />
            </div>
          </AccountContext.Provider>
          <div className="flex justify-between x-sm:flex-col x-sm:justify-center mt-8 lg:mt-20 x-sm:h-auto items-end payment-learn-more-container">
            <div className="w-1/2 x-sm:w-full md:mb-14">
              <h4 className="text-xl font-normal mb-4 text-1-heading-2">
                Pay full statement balance to avoid paying interest.
              </h4>
              <div className="max-h-72 x-sm:h-auto x-sm:max-h-full relative z-20">
                <p className="text-xs pr-4 text-b7 txt-type-div">
                  {learnMore ? (
                    <span>
                      {ReactHtmlParser(longInfo)}
                      <b
                        className="cursor-pointer underline"
                        onClick={() => {
                          setLearnMore(!learnMore);
                        }}
                      >
                        &nbsp;{" Show Less"}
                      </b>
                    </span>
                  ) : (
                    <span>
                      {ReactHtmlParser(desc)}
                      <span>
                        <b
                          className="cursor-pointer underline"
                          onClick={() => {
                            setLearnMore(!learnMore);
                          }}
                        >
                          &nbsp; {more_info_title}
                        </b>
                      </span>
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="x-sm:my-8 mb-8 payment-method-make-payment-container">
              {selectedPaymentMethod && (
                <div className="relative">
                  <div
                    className="flex mb-6 x-sm:mb-0 items-center justify-around cursor-pointer"
                    onClick={showOptions}
                  >
                    <div className="flex">
                      <div className="relative mr-2.5">
                        <img
                          className="w-8 rounded-lg"
                          src={selectedPaymentMethod?.thumbnail_url}
                          alt="bank_logo"
                        />
                        {selectedPaymentMethod?.status === 'REVOKED' && <img
                          className="absolute top-0 right-0 disconnected-payment-method-icon"
                          src={`/apply-card/images/hotlink-ok/finicity/account-revoked.png`}
                        />}
                      </div>
                      <div>
                        <p className="uppercase text-xxs text-1-heading-3">
                          Pay From
                        </p>
                        <p className="text-sm capitalize text-1-heading-3">
                          {selectedPaymentMethod?.bank_name}{" "}
                          {selectedPaymentMethod?.account_type}{" "}
                          {selectedPaymentMethod?.account_last_4_digits}
                        </p>
                      </div>
                    </div>
                    <div className="text-1-heading rotate-90">
                      <span>
                        <ArrowDown />
                      </span>
                    </div>
                  </div>
                  {PaymentDisclaimer && !has_no_dues() && (
                    <p
                      className="text-xs pr-4 text-1-heading-3 w-72 txt-type-div payment-disclaimer-text"
                      data-testid="payment-disclaimer-text"
                    >
                      {renderPaymentDisclaimer(PaymentDisclaimer)}
                    </p>
                  )}
                  <div
                    className={`${showPaymentMethod ? "paymentOptions payment_active z-20" : "paymentOptions"} custom-scrollbar`}
                  >
                    <ul
                      className="px-7 pt-4 max-h-72 overflow-y-auto"
                      ref={listRef}
                    >
                      {paymentMethods.map((method) => {
                        return (
                          <li
                            key={method.id}
                            className="cursor-pointer pb-4 mt-4"
                            onClick={async () => {
                              if(method.status === 'REVOKED') {
                                setShowDisconnectedMethodError({
                                  show: true,
                                  message: <span>Sorry, this bank is currently disconnected <br /> please reconnect or select other bank.</span>
                                });
                              }
                              method && setSelectedPaymentMethod(method);
                              setShowPaymentMethod(false);
                            }}
                          >
                            <div className="flex justify-between">
                              <div className="flex">
                                <div className="relative mr-2.5">
                                  <img
                                    className="w-8 mr-4 rounded-lg"
                                    src={method?.thumbnail_url}
                                    alt="bank_logo"
                                  />
                                  {method?.status === 'REVOKED' && <img
                                    className="absolute top-0 right-0 disconnected-payment-method-icon-list"
                                    src={`/apply-card/images/hotlink-ok/finicity/account-revoked.png`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleOnReconnectClick(method);
                                    }}
                                  />}
                                </div>

                                <div>
                                  <p className="uppercase text-xxs">
                                    {method?.bank_name}
                                  </p>
                                  <p className="text-sm capitalize">
                                    {method?.account_type}{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="text-sm self-center font-bold flex items-center">
                                <p>...{method?.account_last_4_digits}</p>
                                <div className="w-4 ml-4">
                                  {(selectedPaymentMethod?.id === method?.id && selectedPaymentMethod?.status !== 'REVOKED') ? (
                                    <img
                                      src={`${IMAGE_BASE_URL}/check.png`}
                                      alt="selected"
                                    />
                                  ) : null}
                                  {method?.status === 'REVOKED' ? (
                                    <img
                                      // src={`${IMAGE_BASE_URL}/restore-account-access.png`}
                                      src={ReconnectAccountImg}
                                      alt="Reconnect"
                                      style={{width: "25px", height: "25px", maxWidth: "fit-content"}}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if(method.status === 'REVOKED') {
                                          handleOnReconnectClick(method);
                                        }
                                      }}
                                    />
                                  ) : null}

                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                      <li className="pb-8 text-center mt-2">
                        {showPaymentMethod && (
                          <span className="flex relative">
                            <LinkBankAccountButton
                              link_token={linkToken}
                              handleSubmit={onSuccess}
                              toggleInstitutionList={toggleInstitutionList}
                              title="Add Bank"
                              type="link"
                            />
                            {btnLoading && (
                              <div className="loading-black align-spinner"></div>
                            )}
                          </span>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {renderMakePaymenButton()}
            </div>
          </div>
          <div className="flex justify-between x-sm:flex-col-reverse items-center">
            {props.partnerName === "deserve_edu" ? (
              <img
                className="img-powered-by"
                src={`${IMAGE_BASE_URL}/deserve_edu/powered_by_dark.png`}
                alt=""
              />
            ) : (
              <span></span>
            )}
            {paymentRes &&
              paymentRes.auto_pay.institution_unavailable_info_msg && (
                <p
                  data-testid="institution_unavailable_info_msg"
                  className="x-sm:text-center sm:text-center md:text-right lg:text-right xl:text-right pb-6 text-t2 text-b7"
                >
                  {paymentRes.auto_pay.institution_unavailable_info_msg}
                </p>
              )}
          </div>
        </div>
      </>
      {showPaymentSuccess && (
        <PaymentSucess
          meta={paymentSuccessData}
          first_name={data.first_name}
          partnerName={props.partnerName}
        />
      )}
      {showPaymentError && (
        <PaymentError
          makePayment={makePayment}
          partnerName={props.partnerName}
          type="payments"
        />
      )}
      <div
        className={showPaymentMethod ? "backdrop" : ""}
        onClick={() => {
          setShowPaymentMethod(false);
        }}
      ></div>
    </div>
  );
}

export default Payment;
