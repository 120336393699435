import { useState, createContext, useEffect } from "react";
import axios from "axios";
import { ACCOUNT_V3_API_URL, DASHBOARD_API_URL } from "../constants/urls.constants";
import { callApiStarted, commonHeaders } from "../utils/client";
import { getApplicationToken } from "../utils/helpers";
export const AccountDataContext = createContext(null);
export const AccountContextProvider = ({ children }) => {
  const [accountData, setAccountData] = useState({});
  const [accountDataErr, setAccountDataErr] = useState(false);
  const [accountDataErrObj, setAccountDataErrObj] = useState({});

  useEffect(() => {
    const applicationToken =  getApplicationToken();
    const commonHeadersData = commonHeaders();
    const url = ACCOUNT_V3_API_URL;
    callApiStarted("GET", url, commonHeadersData);
    const header = { Authorization: `Bearer ${applicationToken}`,  ...commonHeadersData };
    //TODO: Add common client get method
    axios
      .get(url, { headers: header })
      .then((accountDataRes) => { 
        setAccountData(accountDataRes.data) 
      })
      .catch((err) => {
        setAccountDataErr(true); setAccountDataErrObj(err)
      });
  },[]);

  return (
    <AccountDataContext.Provider value={{ accountData, accountDataErr, accountDataErrObj }}>
      {children}
    </AccountDataContext.Provider>
  );
};