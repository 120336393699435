export const EVENT = {
  dfc_card_overview_screen_viewed: "dfc_card_overview_screen_viewed",
  dfc_auth_apply_now_clicked: "dfc_auth_apply_now_clicked",
  dfc_auth_signin_clicked: "dfc_auth_signin_clicked",
  dfc_auth_signin_phone_screen: "dfc_auth_signin_phone_screen",
  dfc_auth_signup_phone_screen: "dfc_auth_signup_phone_screen",
  dfc_auth_signin_phone_otp_screen: "dfc_auth_signin_phone_otp_screen",
  dfc_auth_signin_phone_otp_invalid_props: "dfc_auth_signin_phone_otp_invalid_props",
  dfc_auth_signup_phone_otp_screen: "dfc_auth_signup_phone_otp_screen",
  dfc_auth_signin_phone_next_clicked: "dfc_auth_signin_phone_next_clicked",
  dfc_auth_signup_phone_next_clicked: "dfc_auth_signup_phone_next_clicked",
  dfc_auth_phone_initiate_succeed: "dfc_auth_phone_initiate_succeed",
  dfc_auth_phone_initiate_failed: "dfc_auth_phone_initiate_failed",
  dfc_auth_signup_phone_otp_next_clicked: "dfc_auth_signup_phone_otp_next_clicked",
  dfc_auth_signin_phone_otp_next_clicked: "dfc_auth_signin_phone_otp_next_clicked",
  dfc_auth_signin_phone_resend_clicked: "dfc_auth_signin_phone_resend_clicked",
  dfc_auth_phone_verify_otp_succeed: "dfc_auth_phone_verify_otp_succeed",
  dfc_auth_phone_verify_otp_failed: "dfc_auth_phone_verify_otp_failed",
  dfc_auth_phone_otp_expired: "dfc_auth_phone_otp_expired",
  dfc_auth_email_screen: "dfc_auth_email_screen",
  dfc_auth_signup_email_next_clicked: "dfc_auth_signup_email_next_clicked",
  dfc_auth_email_initiate_succeed: "dfc_auth_email_initiate_succeed",
  dfc_auth_email_initiate_failed: "dfc_auth_email_initiate_failed",
  dfc_auth_email_otp_screen: "dfc_auth_email_otp_screen",
  dfc_auth_email_otp_next_clicked: "dfc_auth_email_otp_next_clicked",
  dfc_auth_email_otp_resend_clicked: "dfc_auth_email_otp_resend_clicked",
  dfc_auth_email_verify_otp_succeed: "dfc_auth_email_verify_otp_succeed",
  dfc_auth_email_verify_otp_failed: "dfc_auth_email_verify_otp_failed",
  dfc_auth_email_otp_expired: "dfc_auth_email_otp_expired",
  dfc_auth_verify_email_otp_cb_screen_viewed: "dfc_auth_verify_email_otp_cb_screen_viewed",
  dfc_auth_verify_email_otp_from_email_cb_called: "dfc_auth_verify_email_otp_from_email_cb_called",
  dfc_auth_verify_email_otp_from_email_cb_ok: "dfc_auth_verify_email_otp_from_email_cb_ok",
  dfc_auth_verify_email_otp_from_email_cb_fail: "dfc_auth_verify_email_otp_from_email_cb_fail",
  dfc_auth_existing_user_screen_viewed: "dfc_auth_existing_user_screen_viewed",
  dfc_auth_error_screen_viewed: "dfc_auth_error_screen_viewed",

  dfc_auth_ssn_dob_form_valid: "dfc_auth_ssn_dob_form_valid",
  dfc_auth_ssn_dob_next_clicked: "dfc_auth_ssn_dob_next_clicked",
  dfc_auth_ssn_dob_submit_succeed: "dfc_auth_ssn_dob_submit_succeed",
  dfc_auth_ssn_dob_submit_failed: "dfc_auth_ssn_dob_submit_failed",
  dfc_auth_ssn_dob_screen: "dfc_auth_ssn_dob_screen",

  onb_application_started: 'onb_application_started',

  onb_application_failed: 'onb_application_failed',
  onb_application_creation_unavailable: 'onb_application_creation_unavailable',

  login_with_appleId: 'login_with_appleId',
  login_with_googleId: 'login_with_googleId',
  google_signin_failed: 'google_signin_failed',
  login_with_email: 'login_with_email',

  onb_email_next_clicked: 'onb_email_next_clicked',
  onb_email_submit_failed: 'onb_email_submit_failed',

  onb_email_code_next_clicked: 'onb_email_code_next_clicked',
  onb_email_code_submitted: 'onb_email_code_submitted',
  onb_email_resend_code_clicked: 'onb_email_resend_code_clicked',
  onb_email_resend_code_failed: 'onb_email_resend_code_failed',
  onb_email_code_submit_failed: 'onb_email_code_submit_failed',

  onb_name_next_clicked: 'onb_name_next_clicked',
  onb_name_submitted: 'onb_name_submitted',
  onb_name_submit_failed: 'onb_name_submit_failed',

  onb_phone_next_clicked: 'onb_phone_next_clicked',
  onb_phone_submitted: 'onb_phone_submitted',
  onb_phone_submit_failed: 'onb_phone_submit_failed',

  onb_phone_code_next_clicked: 'onb_phone_code_next_clicked',
  onb_phone_code_submitted: 'onb_phone_code_submitted',
  onb_phone_code_submit_failed: 'onb_phone_code_submit_failed',

  onb_phone_resend_code_clicked: 'onb_phone_resend_code_clicked',
  onb_phone_resend_code_submit_failed: 'onb_phone_resend_code_submit_failed',

  onb_phone_uploaded: 'onb_phone_uploaded',
  onb_phone_upload_failed: 'onb_phone_upload_failed',

  onb_dob_next_clicked: 'onb_dob_next_clicked',
  onb_dob_submitted: 'onb_dob_submitted',

  onb_address_next_clicked: 'onb_address_next_clicked',
  onb_address_submitted: 'onb_address_submitted',

  onb_meta_data_submit_failed: 'onb_meta_data_submit_failed',

  onb_ssn_vgs_failed: 'onb_ssn_vgs_failed',
  onb_ssn_next_clicked: 'onb_ssn_next_clicked',
  onb_ssn_submitted: 'onb_ssn_submitted',
  onb_ssn_submit_failed: 'onb_ssn_submit_failed',
  onb_ssn_submit_store_vgs_failed: 'onb_ssn_submit_store_vgs_failed',

  onb_income_source_selected: 'onb_income_source_selected',
  onb_employment_status_selected: 'onb_employment_status_selected',
  onb_bank_account_age_selected: 'onb_bank_account_age_selected',
  onb_employment_current_status_selected: 'onb_employment_current_status_selected',
  onb_income_type_selected: 'onb_income_type_selected',
  onb_income_submitted: 'onb_income_submitted',
  onb_income_next_clicked: 'onb_income_next_clicked',

  onb_income_submit_failed: 'onb_income_submit_failed',

  onb_living_situation_selected: 'onb_living_situation_selected',
  onb_rent_next_clicked: 'onb_rent_next_clicked',
  onb_rent_submitted: 'onb_rent_submitted',
  onb_rent_submit_failed: 'onb_rent_submit_failed',

  onb_TNC_accepted: 'onb_TNC_accepted',
  onb_TNC_saved: 'onb_TNC_saved',
  onb_TNC_submit_failed: 'onb_TNC_submit_failed',
  onb_submit_application_clicked: 'onb_submit_application_clicked',

  onb_bank_connected: 'onb_bank_connected',
  onb_bank_next_clicked: 'onb_bank_clicked',
  onb_bank_connect_failed: 'onb_bank_connect_failed',
  onb_unlock_credit_bureau_submit_failed: 'onb_unlock_credit_bureau_submit_failed',

  onb_bank_connect_close_oauth: 'onb_bank_connect_close_oauth',
  onb_bank_connect_error: 'onb_bank_connect_error',
  onb_bank_connect_exit: 'onb_bank_connect_exit',
  onb_bank_connect_fail_oauth: 'onb_bank_connect_fail_oauth',
  onb_bank_connect_handoff: 'onb_bank_connect_handoff',
  onb_bank_connect_mached_select_intitution: 'onb_bank_connect_mached_select_intitution',
  onb_bank_connect_mached_select_verify_method: 'onb_bank_connect_mached_select_verify_method',
  onb_bank_connect_open: 'onb_bank_connect_open',
  onb_bank_connect_open_oauth: 'onb_bank_connect_open_oauth',
  onb_bank_connect_select_brand: 'onb_bank_connect_select_brand',
  onb_bank_connect_select_intitution: 'onb_bank_connect_select_intitution',
  onb_bank_connect_submit_credentials: 'onb_bank_connect_submit_credentials',
  onb_bank_connect_submit_mfa: 'onb_bank_connect_submit_mfa',
  onb_bank_connect_transition_view: 'onb_bank_connect_transition_view',


  onb_auto_poll_failed: 'onb_auto_poll_failed',

  onb_refresh_token_failed: 'onb_refresh_token_failed',
  onb_additional_income_submit_failed: 'onb_additional_income_submit_failed',
  onb_employment_duration_selected: "onb_employment_duration_selected",
  add_bank: 'add_bank',

  onb_repapply_application_failed: 'onb_repapply_application_failed',

  payment_failed: 'payment_failed',
  payment_bank_connected: 'payment_bank_connected',
  one_time_payment_posting_failed: 'one_time_payment_posting_failed',
  pay_bank_account_linked_token_fetch_failed: 'pay_bank_account_linked_token_fetch_failed',
  auto_pay_payment_posting_failed: 'auto_pay_payment_posting_failed',
  auto_pay_setup_success: `auto_pay_setup_success`,
  setup_auto_pay: `setup_auto_pay`,
  update_auto_pay: `update_auto_pay`,
  auto_pay_setup_failed: `auto_pay_setup_failed`,

  homePayClicked : "home_pay_clicked",

  payDetailViewed : "pay_detail_viewed",
  payClicked : "pay_clicked",
  autoPayClicked : "auto_pay_clicked",

  addBankClicked : "add_bank_clicked",
  payBankGetTokenApi : "pay_bank_get_token_api",
  payBankTokenApiSuccess : "pay_bank_token_api_success",
  payBankTokenApiFailed : "pay_bank_token_api_failed",

  plaidConnectBankViewed : "plaid_connect_bank_viewed",
  plaidConnectBankOauthStarted : "plaid_connect_bank_oauth_started",
  plaidConnectBankInboundReceived : "plaid_connect_bank_inbound_received",
  plaidConnectBankOauthSuccess : "plaid_connect_bank_oauth_success",
  plaidConnectBankOauthFailed : "plaid_connect_bank_oauth_failed",
  plaidConnectBankDataReceived : "plaid_connect_bank_data_received",
  plaidConnectBankDataReceivedFailed : "plaid_connect_bank_data_received_failed",

  payAddBankApiRequested : "pay_add_bank_api",
  payAddBankApiSuccess : "pay_add_bank_api_success",
  payAddBankApiFailed : "pay_add_bank_api_failed",
  payBankPlaidConfigFailed : "pay_bank_plaid_config_failed",

  payApiRequested : "pay_api",
  payApiSuccess : "pay_api_success",
  payApiFailed : "pay_api_failed",

  profileCardFreezed : "profile_card_freezed",
  profileInternationalTxnPaused : "profile_international_txn_paused",

  homeProfile : "home_profile",
  servicing_dashboard_screen_viewed : "servicing_dashboard_screen_viewed",
  servicing_dashboard_screen_view_initiated : "servicing_dashboard_screen_view_initiated",
  servicing_dashboard_screen_view_failed : "servicing_dashboard_screen_view_failed",

  profile_edit_address_screen_viewed: 'profile_edit_address_screen_viewed',
  profile_edit_address_screen_view_failed: 'profile_edit_address_screen_view_failed',
  profile_contact_us_screen_viewed: 'profile_contact_us_screen_viewed',
  profile_edit_address_next_clicked: 'profile_edit_address_next_clicked',

  freeze_card_failed: "freeze_card_failed",
  international_card_activation_failed: "international_card_activation_failed",

  onb_smartstreet_api_success: "onb_smartstreet_api_success",
  onb_smartstreet_api_failed: "onb_smartstreet_api_failed",

  onb_login_with_google_callback_marketing_data: 'onb_login_with_google_callback_marketing_data',

  onb_marketing_data_on_email_sign_in: 'onb_marketing_data_on_email_sign_in',

  onb_marketing_url_stored: 'onb_marketing_url_stored',
  onb_marketing_url_readed: 'onb_marketing_url_readed',

  onb_marketing_url_data_on_confirm_email_link: 'onb_marketing_url_data_on_confirm_email_link',
  onb_payment_type_selected: "onb_payment_type_selected",

  onb_card_reactivation_button_clicked: 'onb_card_reactivation_button_clicked',
  onb_cha_acceptance_button_clicked: 'onb_cha_acceptance_button_clicked',
  onb_cha_acceptance_success: 'onb_cha_acceptance_success',
  onb_cha_acceptance_failed: 'onb_cha_acceptance_failed',
  onb_account_not_active: "onb_account_not_active",
  onb_account_active: "onb_account_active",
  onb_account_api_failed: "onb_account_api_failed",
  onb_mobile_web: "onb_mobile_web",
  onb_desktop_web: "onb_desktop_web",
  payments_screen_view_initiated: "payments_screen_view_initiated",
  payments_screen_viewed: "payments_screen_viewed",
  payments_screen_view_failed: "payments_screen_view_failed",
  autopay_screen_viewed: "autopay_screen_viewed",
  autopay_screen_view_initated: "autopay_screen_view_initated",
  autopay_screen_view_failed: "autopay_screen_view_failed",
  autopay_bank_connected: 'autopay_bank_connected',
  profile_screen_view_failed: "profile_screen_view_failed",
  profile_screen_viewed: "profile_screen_viewed",
  profile_screen_view_initiated: "profile_screen_view_initiated",
  onb_unlock_credit_bureau_submit_submitted: "onb_unlock_credit_bureau_submit_submitted",

  card_activation_screen_viewed: "card_activation_screen_viewed",
  card_activation_button_clicked: "card_activation_button_clicked",

  finicity_add_account_failed: "finicity_add_account_failed",
  finicity_add_account_success: "finicity_add_account_success",
  finicity_connect_fix_account_failed: "finicity_connect_fix_account_failed",

  api_started: "api_started",
  api_success: "api_success",
  api_failed: "api_failed",
}
