import { useState, useRef } from "react";

import useDebounce from "../../hooks/useDebounce";

import FinicityAccounts from "./FinicityAccounts";
import FinicityInstitutions from "./FinicityInstitutions";
import FinicityWrapper from "./FinicityWrapper";
import CloseIcon from "./components/CloseIcon";
import InstitutionsError from "./components/InstitutionsError";
import MagnifyingGlassIcon from "./components/MagnifyingGlassIcon";
import useFinicity from "./useFinicity";
import { getApplicationToken } from "../../utils/helpers";
import AccountConnectionError from "./components/AccountConnectionError";
import { FinicityLoading } from "./components/FinicityLoading";

const FinicityConnect = ({ close, onSuccessfulBankConnect }) => {
  let accessToken = getApplicationToken();

  const [searchTerm, setSearchTerm] = useState("");
  const { debouncedValue } = useDebounce(searchTerm, 500);
  const input_ref = useRef(null);

  const {
    current_view,
    isLoading,
    fetchingConnectURL,
    error,
    institutions,
    institutionsMetadata,
    accounts,
    initiateFinicityConnect,
    connectMethods,
    showConnectingBankMessage,
    bankConnected,
    showConnectingFailureMessage,
    connectMethodsTryAgain
  } = useFinicity({
    accessToken,
    filter: debouncedValue,
  });

  return (
    <FinicityWrapper>
      {fetchingConnectURL ? (
        <FinicityLoading />
      ) : current_view === "INSTITUTIONS" ? (
        <>
          <div className="w-full flex justify-end items-center">
            {isLoading ? (
              <FinicityLoading />
            ) : null}
            <div className="finicity-box_close">
              <CloseIcon onClick={close} />
            </div>
          </div>
          <h1 className="font-h4 text-center text-t1-headline institution-title">
            Select Institution
          </h1>
          <div className="px-4 w-full flex items-center bg-white gap-2 h-10 mb-8 rounded-xl">
            <div>
              <MagnifyingGlassIcon />
            </div>
            <input
              className="w-full text-t1-headline"
              placeholder="Search Institutions"
              type="text"
              value={searchTerm}
              ref={input_ref}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            {searchTerm && (
              <CloseIcon
                onClick={() => {
                  setSearchTerm("");
                }}
              />
            )}
          </div>
          {error ? (
            <InstitutionsError
              type={searchTerm ? "SEARCH_BANK_ERROR" : "DEFAULT_BANK_ERROR"}
            />
          ) : (
            <FinicityInstitutions
              showLoading={isLoading}
              searchTerm={debouncedValue}
              close={close}
              institutions={institutions || []}
              institutionsMetadata={institutionsMetadata}
              onInstitutionClick={(institution) => {
                initiateFinicityConnect(institution);
              }}
            />
          )}
        </>
      ) : current_view === "ACCOUNTS" ? (
        <>
          <div className="w-full flex justify-end items-center">
            <div className="finicity-box_close">
              <CloseIcon onClick={close} />
            </div>
          </div>
          {showConnectingFailureMessage ? (
            // Rahul: Consumer Finicity - No use case was handled for this component
            <AccountConnectionError tryAgainHandler={connectMethodsTryAgain}></AccountConnectionError>
          ) : (
            <FinicityAccounts
              connectMethods={(methods) => {
                connectMethods(methods);
              }}
              accounts={accounts}
              showConnectingBankMessage={showConnectingBankMessage}
              bankConnected={bankConnected}
              onSuccessfulBankConnect={onSuccessfulBankConnect}
              onContinue={close}
            />
          )}
        </>
      ) : null}
    </FinicityWrapper>
  );
};

export default FinicityConnect;
