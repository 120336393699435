const skeleton = () => {
  return (
    <div className="grid grid-cols-3 gap-3" data-testid="institutions-skeleton">
      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((_,index) => {
        return (
          // Rahul: Consumer Finicity - Due to old tailwind version
          <div key={index} className="bg-slate-400 rounded-md w-[153px] h-[86px] flex items-center justify-center cursor-pointer w-40 h-20 animate-pulse"></div>
        );
      })}
    </div>
  );
};

export default skeleton;
