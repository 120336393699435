import * as Sentry from "@sentry/react";
import axios from "axios";
import { header as getDefaultHeaders } from "../../../utils/client";
import { LOGIN_URL, REFRESH_TOKEN } from "../../../constants/urls.constants";
import {
  getApplicationRefreshToken,
  getApplicationToken,
  setApplicationToken,
  setApplicationRefreshToken,
  clearApplicationToken,
  clearApplicationRefreshToken
} from "../../../utils/helpers";

const axiosClient = axios.create();

export const refreshToken = async () => {
  try {
    let applicationToken = getApplicationToken();

    const res = await AxiosClient.postData(REFRESH_TOKEN, {refresh_token: getApplicationRefreshToken()}, applicationToken);

    if (res.data) {
      setApplicationToken(res.data.id_token);
      setApplicationRefreshToken(res.data.refresh_token);
      return Promise.resolve(res.data);
    } else {
      window.location.href = '/apply-card/error';
    }
  } catch (err) {
    Sentry.captureException(err);
    if (err?.response?.status === 401) {
      clearApplicationToken();
      clearApplicationRefreshToken();
      window.location.href = LOGIN_URL;
    }
    return Promise.reject(err);
  }
}

class AxiosClient {
  static client;

  static init() {
    this.client = axiosClient;

    this.client.interceptors.response.use((res) => {
      return res;
    }, async (err) => {
      try {
        if (err?.response?.status === 401) {
          await refreshToken();
        }
      } catch (e) {
        Sentry.captureException(err);
      }
      return Promise.reject(err);
    });
  }

  static MAX_RETRY = 10;
  static async getData(url, accessToken, params = {}) {
    return this.client
      .get(url, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data)
      .catch((err) => {
        Sentry.captureException(err);
        return Promise.reject(err);
      });
  }

  static async getDataWithRetry(
    url,
    accessToken,
    params = {},
    condition,
    currentRetry = 1
  ) {
    return this.client
      .get(url, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(async (resp) => {
        if (
          condition &&
          !condition(resp.data) &&
          currentRetry < this.MAX_RETRY
        ) {
          currentRetry++;
          return await AxiosClient.getDataWithRetry(
            url,
            accessToken,
            params,
            condition,
            currentRetry
          );
        }

        return resp.data;
      })
      .catch(async (err) => {
        if (currentRetry <= this.MAX_RETRY) {
          currentRetry++;
          return await AxiosClient.getDataWithRetry(
            url,
            accessToken,
            params,
            condition,
            currentRetry
          );
        } else {
          Sentry.captureException(err);
        }

        return Promise.reject(err);
      });
  }

  static async postData(url, data, accessToken, headers = {}) {
    return this.client
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          ...getDefaultHeaders(),
          ...headers,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Sentry.captureException(err);
        return Promise.reject(err);
      })
  }

  static async patchData(url, data, accessToken) {
    return this.client
      .patch(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data)
      .catch((err) => {
        Sentry.captureException(err);
        return Promise.reject(err);
      });
  }

  static async putData(url, data, accessToken, headers = {}) {
    return this.client
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          ...headers,
        },
      })
      .then((resp) => resp.data)
      .catch((err) => {
        Sentry.captureException(err);
        return err;
      });
  }

  static async deleteData(url, accessToken, data) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const config = data
      ? {
          headers,
          data,
        }
      : {
          headers,
        };

    return this.client
      .delete(url, config)
      .then((resp) => resp.data)
      .catch((err) => {
        Sentry.captureException(err);
        return err;
      });
  }
}

AxiosClient.init();

export default AxiosClient;
