import {useState, useCallback, useRef, useEffect} from "react";
import { Connect } from "connect-web-sdk";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";

import {
  getInstitutions,
  getFinicityLiteUrl,
  getFincityAccounts,
  syncPaymentMethods,
  startFinicityConnectFix,
} from "./api/finicity";

const CURRENT_VIEW_ENUM = {
  INSTITUTIONS: "INSTITUTIONS",
  ACCOUNTS: "ACCOUNTS",
};

const useFinicity = ({ accessToken, filter = undefined, fetchOnLoad = true }) => {
  const [finicityState, setFinicityState] = useState({
    current_view: CURRENT_VIEW_ENUM.INSTITUTIONS,
  });
  const [institutionErr, setInstitutionErr] = useState(false);

  const institution_login_id_ref = useRef(null);
  const customer_id_ref = useRef(null);
  const institution_name_ref = useRef(null);

  useEffect(() => {
    return () => {
      Connect.destroy();
    }
  }, []);

  const { data, error, isFetching } = useQuery({
    queryKey: ["institutions", filter, accessToken],
    queryFn: () => {
      if (institutionErr) {
        setInstitutionErr(false);
      }
      return getInstitutions({ accessToken, filter });
    },
    enabled: fetchOnLoad,
    retry: false
  });

  const { default_institutions, institutions, metadata } = data || {};

  const connectMethods = useCallback(
    async (accounts) => {
      setFinicityState({
        ...finicityState,
        showConnectingBankMessage: true,
      });

      const payload = {
        customer_id: customer_id_ref.current,
        institution_login_id: institution_login_id_ref.current,
        name: institution_name_ref.current,
        accounts,
      };

      try {
        await syncPaymentMethods(accessToken, payload);

        setFinicityState({
          ...finicityState,
          showConnectingBankMessage: false,
          bankConnected: true,
        });
      } catch (err) {
        setFinicityState({
          ...finicityState,
          showConnectingFailureMessage: true,
          showConnectingBankMessage: false,
          bankConnected: false,
        });
      }
    },
    [finicityState, accessToken]
  );

  const connectMethodsTryAgain = () => {
    setFinicityState({
      ...finicityState,
      showConnectingFailureMessage: false
    });
  }

  const initiateFinicityConnect = useCallback(
    async (institution) => {
      institution_name_ref.current = institution.name;

      setFinicityState({
        ...finicityState,
        loading: true,
      });

      try {
        const { connect_url, customer_id } = await getFinicityLiteUrl({
          accessToken,
          institution_id: institution.id,
        });

        customer_id_ref.current = customer_id;

        // hide the finicity accounts view modal
        const wrapperElm = document.getElementById("finicityWrapper");
        wrapperElm.style.visibility = "hidden";

        Connect.launch(
          connect_url,
          {
            onDone: async (event) => {
              const { reason, code } = event;
              if (reason === "complete" && code === 200) {
                // show the accounts view modal
                wrapperElm.style.visibility = "visible";
                // get accounts from BFF (savings or checkings)  -> /bff/check-finicity-accounts (PUT) by passing institutionLoginId and customer id from state
                // const {institution_login_id, customer_id} = finicityState;
                try {
                  const accounts = await getFincityAccounts(accessToken, {
                    institution_login_id: institution_login_id_ref.current,
                    customer_id: customer_id_ref.current,
                    name: institution_name_ref.current,
                  });
                  setFinicityState({
                    ...finicityState,
                    current_view: CURRENT_VIEW_ENUM.ACCOUNTS,
                    loading: false,
                    accounts,
                  });
                } catch (err) {
                  setInstitutionErr(true);
                  setFinicityState({
                    ...finicityState,
                    loading: false,
                  });
                }

              }
            },
            onCancel: (event) => {
              const { reason } = event;
              if (reason === "exit") {
                window.location.reload();
              }
            },
            onError: () => {
              setFinicityState({
                ...finicityState,
                loading: false,
              });
            },
            onUser: (userActionObject) => {
              const { action, success, institutionLoginId } = userActionObject;
              if (
                (action === "AddAccounts" || action === "AddOAuthAccounts") &&
                success && institutionLoginId
              ) {
                // set the institutionLoginId in the state
                // setFinicityState({...finicityState, institution_login_id: institutionLoginId})
                institution_login_id_ref.current = institutionLoginId.toString();
              }
            }
          },
          {
            overlay: "rgba(199,201,199, 0.5);z-index: 10000;",
          }
        );
      } catch (e) {
        setInstitutionErr(true);
        setFinicityState({
          ...finicityState,
          loading: false,
        });
      }
    },
    [accessToken, finicityState]
  );

  const getStartFinicityConnectFixPayload = (paymentMethod) => {
    const { account_last_4_digits, account_type, bank_name, id, name, status, thumbnail_url, type } = paymentMethod;

    const { finuserid } = name.split(',').reduce((acc, pair) => {
      const [key, value] = pair.trim().split(' ');
      acc[key] = value;
      return acc;
    }, {});

    return {
      customer_id: finuserid,
      name: bank_name,
      account: {
        account_last_4_digits,
        account_type,
        bank_name,
        id,
        name,
        status,
        thumbnail_url,
        type
      }
    }
  }

  const initiateFinicityConnectFix = useCallback(
    async (paymentMethod, onConnectFixStart = () => {}, onConnectFixError = () => {}) => {
      try {
        institution_name_ref.current = paymentMethod.bank_name;

        // Get the connect fix url
        const res = await startFinicityConnectFix({
          accessToken,
          payload: undefined,
          payment_method_id: paymentMethod.id,
        });

        if (res.status === 204) {
          window.location.reload();
        }
        const { connect_url } = res.data || {};

        if (connect_url) {
          Connect.launch(
            connect_url,
            {
              onDone: async (event) => {
                try {
                  const { reason, code } = event;
                  if (reason === "complete" && code === 200) {
                    // Fix the payment method
                    onConnectFixStart();
                    const res = await startFinicityConnectFix({
                      accessToken,
                      payload: getStartFinicityConnectFixPayload(paymentMethod),
                      payment_method_id: paymentMethod.id,
                    });

                    if (res.status === 204) {
                      window.location.reload();
                    }
                  }
                } catch(error) {
                  Sentry.captureException(error);
                  onConnectFixError();
                }

              },
              onCancel: (event) => {
                const { reason } = event;
                if (reason === "exit") {
                  window.location.reload();
                }
              },
              onError: (event) => {
                Sentry.captureException(event);
                onConnectFixError();
              },
              onUser: (userActionObject) => {
                const { success, institutionLoginId } = userActionObject;
                if (success && institutionLoginId) {
                  // set the institutionLoginId in the state
                  // setFinicityState({...finicityState, institution_login_id: institutionLoginId})
                  institution_login_id_ref.current =
                    institutionLoginId.toString();
                }
              },
              onLoad: () => {
              },
            },
            {
              overlay: "rgba(199,201,199, 0.5);z-index: 10000;",
            }
          );
        } else {
          onConnectFixError();
          return {
            reload: true,
          };
        }
      } catch (e) {
        onConnectFixError();
      }
    },
    [accessToken]
  );

  return {
    initiateFinicityConnect,
    connectMethods,
    error: error || institutionErr,
    isLoading: isFetching,
    fetchingConnectURL: finicityState.loading,
    accounts: finicityState.accounts,
    current_view: finicityState.current_view,
    institutions: filter ? institutions : default_institutions,
    institutionsMetadata: metadata,
    showConnectingBankMessage: finicityState.showConnectingBankMessage,
    showConnectingFailureMessage: finicityState.showConnectingFailureMessage,
    connectMethodsTryAgain,
    bankConnected: finicityState.bankConnected,
    initiateFinicityConnectFix,
  };
};

export default useFinicity;
