const FinicityWrapper = ({ children }) => {
  return (
    <>
      <div
        className="min-h-[739px] h-full w-full flex justify-center items-center absolute rounded-md backdrop-blur inset-0"
        // Rahul: Consumer Finicity - CSS handling due to older tailwind version
        style={{ minHeight: "739px" }}
      />
      <div
        id="finicityWrapper"
        className="min-h-[739px] h-full w-full flex justify-center items-center absolute inset-0 bg-black bg-opacity-50 z-50 min-h-739 new-design-guidelines "
        // Rahul: Consumer Finicity - CSS handling due to older tailwind version
        style={{ minHeight: "739px" }}
      >
        <div
          // Rahul: Consumer Finicity - CSS handling due to older tailwind version
          // className="w-[327px] sm:w-[458px] md:w-[448px] xl:w-[490px] 2xl:w-[512px] h-4/5 lg:h-[600px] xl:h-[598px] 2xl:h-[640px]"
          className="w-327px sm:w-458px md:w-576px xl:w-490px 2xl:w-512px h-4/5 lg:h-600px xl:h-598px 2xl:h-640px"
        >
          <div className="rounded-2xl bg1 w-full h-full overflow-hidden finicity-box">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default FinicityWrapper;
