// import { SegmentAnalytics } from '@segment/analytics.js-core';

import { DESKTOP_OS_LIST } from "../constants/common.constants";
import { UNKNOWN_LABEL } from "../constants/label.constants";
import { currentProductAlias } from "../core/multiProduct/product-alias";
import ClientStorage from "./client-storage";
import { getBrowserAndOS, getSessionId } from "./helpers";

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

// Initialize the library
// analytics.initialize(integrationSettings);

export const track = (name: string, properties: any = {}, callbackFn: () => void = () => {}) => {
  const browserOSInfo = getBrowserAndOS();
  const appConfig = window['appConfig'];
  
  properties['product_alias'] = currentProductAlias();
  properties['isDesktopWeb'] = DESKTOP_OS_LIST.includes(browserOSInfo?.osName || UNKNOWN_LABEL);
  properties['browser'] = browserOSInfo?.browserName || UNKNOWN_LABEL;
  properties['operating_system'] = browserOSInfo?.osName || UNKNOWN_LABEL;
  properties['partner_name'] = appConfig?.PARTNER_NAME || UNKNOWN_LABEL;
  properties['environment'] = appConfig?.ENVIRONMENT || UNKNOWN_LABEL;
  properties['tenant_id'] = appConfig?.PROGRAM_ID || UNKNOWN_LABEL;
  properties['session_trace_id'] = ClientStorage.getLocal('session_trace_id') || UNKNOWN_LABEL;
  properties['sign_in_session_id'] = getSessionId() || UNKNOWN_LABEL;
  // console.log(`####${name}${JSON.stringify(properties)}####`);
  window?.analytics?.track(name, properties, callbackFn);
  return null;
};

export const trackFailure = (name: string, err: any = {}, callbackFn: () => void = () => {}) => {
  let properties = {
      status: err?.response?.status,
      errorCode: err?.response?.data?.code,
      errorPayload: err?.response?.data || err?.message || err
  };
  window?.analytics?.track(name, properties, callbackFn);
};

export let identifyWithPhone = (email, phone) => {
  return window?.analytics?.identify(phone,{
    email: email,
    phone: phone
  });
};
